import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";




const StyledSection = styled(Section)`
margin-top: 50px;
min-height: 75vh;
display: flex;
align-items: center;

@media only screen and (min-width: 1000px) {
  min-height: 900px;
}
@media only screen and (max-width: 1000px) {
  .lets-talk{
    padding-bottom: 1rem;
    font-size: 2.5rem !important;
  }
}

`





const HeroStatic = ({image}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}

      <StyledSection className="position-relative" css={{ backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "top", position: "relative", width: "100%" }}>
        <Container>
        </Container>

      </StyledSection>
    </>
  );
};

export default HeroStatic;
