import React from "react";
import { Element } from "react-scroll";
import { graphql } from 'gatsby'

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/peel-creative/Hero";
import TextBlock from "../sections/peel-creative/TextBlock";
import Tagline from "../sections/peel-creative/Tagline";
import Work from "../sections/peel-creative/Work";
import WorkSection from "../sections/peel-creative/WorkSection"
import { Title, Button, Section, Box, Text } from "../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import HeroImage from "../assets/peel-creative/about_hero.jpg"
import HeroStatic from "../sections/peel-creative/HeroStatic";

const CatergoryPage = ({ data }) => {
  if (!data) return null
  const document = data.allPrismicWorkCategory.edges[0].node
  return (
    <>
      <PageWrapper>
      <HeroStatic image={document.data.hero.url} />


        <TextBlock title={document.data.category_name.text} title_color="Black" copy={document.data.category_description.text} />
        <TextBlock title="Why Peel" title_color="#9fc0c5" copy="<strong>In order to see the solution, we must first see the problem.</strong>  It’s about understanding the business, the issues facing the business, the barriers, the objectives, the message. We do this with you. Peeling away the things that get in the way - getting to the core of the issue. It’s not always easy but it’s all about caring enough to get through the barriers. We look for the emotional truth of the product or service because the truth is the thing which resonates with your customers. Once we understand the emotional triggers that drive choice, we build the design around that truth. We find a way to celebrate the promise of the product." />

        <Section>

        <Container>
        <WorkSection item={document} cat/>

        </Container>
        </Section>
      </PageWrapper>
    </>
  );
};


export const query = graphql`
query CatergoryPageQuery($uid: String) {
  allPrismicWorkCategory(filter: {uid: {eq: $uid}}) {
    edges {
      node {
        id
        data {
          category_name {
            text
          }
          category_description {
            text
          }
          hero {
            url
          }
          featured_works {
            featured_work {
              document {
                ... on PrismicWork {
                  id
                  data {
                    project_name {
                      text
                    }
                    hero_image {
                      localFile {
                        childrenImageSharp {
                          fluid {
                            base64
                            tracedSVG
                            srcWebp
                            srcSetWebp
                            originalImg
                            originalName
                          }
                        }
                      }
                    }
                  }
                  uid
                }
              }
            }
          }
          other_work {
            other_work_item {
              document {
                ... on PrismicWork {
                  id
                  data {
                    hero_image {
                      localFile {
                        childImageSharp {
                          fluid {
                            base64
                            tracedSVG
                            srcWebp
                            srcSetWebp
                            originalImg
                            originalName
                          }
                        }
                      }
                    }
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
export default CatergoryPage;
